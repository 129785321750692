<template>
  <div class="contents userMgt users">
    <div class="title flexB">
      <h2>생육데이터 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <!-- <option disabled selected value>선택</option> -->
          <option disabled value="crops">작물</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold withBtn">
        생육데이터 리스트
        <el-button class="line" @click="handleDownload" :loading="btnDisabled">
          <img
            v-if="btnDisabled == false"
            src="@/assets/images/icon_svg/excel.svg"
            alt="icon"
            class="normal"
          />

          다운로드</el-button
        >
      </h2>
      <table>
        <colgroup>
          <col style="width: 7%" />
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 12%" />
          <col style="width: 12%" />
          <col style="width: 12%" />
          <col style="width: 12%" />
          <col style="width: 15%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>농장주</th>
          <th>작물</th>
          <th>시료</th>
          <th>초장</th>
          <th>잎개수</th>
          <th>잎길이</th>
          <th>잎너비</th>
          <th>등록일</th>
        </tr>
        <tr v-for="(data, i) in growthList" :key="i">
          <td>{{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</td>
          <td>{{ data.farmer ? data.farmer.username : "" }}</td>
          <td>
            <router-link to @click.native="handleDetail(data._id)">
              {{ data.crops }}
            </router-link>
          </td>
          <td>{{ data.sample }}</td>
          <td>{{ data.totalLT }}cm</td>
          <td>{{ data.leafCount }}개</td>
          <td>{{ data.leafLength }}cm</td>
          <td>{{ data.leafWidth }}cm</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
        </tr>
      </table>
      <NoData v-if="growthList.length == 0" />
      <div class="pagination" v-else>
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchGrowthsList, fetchGrowthDumpList } from "@/api/admin";
import { format } from "@/mixins/format";
import XLSX from "xlsx";
import NoData from "@/components/contents/Nodata";

export default {
  components: { NoData },

  mixins: [format],
  name: "growths",
  data() {
    return {
      moment: moment,
      inputMode: "crops",
      searchWord: "",
      growthList: [],
      dumpList: [],
      total: 0,
      currentPage: 1,
      btnDisabled: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "6");
  },
  mounted() {
    this.getGrowthList();
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleDetail(id) {
      this.$router.push({ name: "growthDetail", query: { id: id } });
    },

    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.growthList = [];
      this.total = 0;
      this.currentPage = 1;
      this.getGrowthList();
    },
    search() {
      if (this.inputMode == "") {
        this.openAlert("검색 조건을 선택해 주세요.");
      }
      this.currentPage = 1;
      this.getGrowthList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getGrowthList();
    },
    getGrowthList() {
      let params = {
        page: this.currentPage,
        inputMode: this.inputMode,
        searchWord: this.searchWord,
      };
      fetchGrowthsList(params).then((res) => {
        if (res.data.status == 200) {
          this.growthList = res.data.data;
          this.total = res.data.total;
          this.currentPage = res.data.page;
        } else if (res.data.status == 409) {
          this.$router.push("/");
          return this.openAlert(res.data.message);
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    async handleDownload() {
      this.btnDisabled = true;
      //엑셀파일로 다운로드

      let params = {
        inputMode: this.inputMode,
        searchWord: this.searchWord,
      };

      let res = await fetchGrowthDumpList(params);
      if (res.data.status == 200) {
        this.dumpList = res.data.data;
      } else {
        return this.openAlert(res.data.message);
      }
      let growthData = [];
      this.dumpList.map((item) => {
        Object.keys(item).forEach((k) => {
          let data = {
            농장주: item.farmer ? item.farmer.username : "",
            시료번호: item.sample.replace(/[^0-9]/g, ""),
            조사항목: "",
            조사항목코드: "",
            조사항목값: "",
            등록일자: moment(item.createdAt).format("YYYYMMDD"),
          };
          switch (k) {
            case "totalLT":
              data.조사항목 = "초장";
              data.조사항목코드 = 10000001;
              data.조사항목값 = item[k];
              growthData.push(data);
              break;
            case "leafCount":
              data.조사항목 = "엽수";
              data.조사항목코드 = 10000003;
              data.조사항목값 = item[k];
              growthData.push(data);
              break;
            case "leafLength":
              data.조사항목 = "엽장";
              data.조사항목코드 = 10000004;
              data.조사항목값 = item[k];
              growthData.push(data);
              break;
            case "leafWidth":
              data.조사항목 = "엽폭";
              data.조사항목코드 = 10000005;
              data.조사항목값 = item[k];
              growthData.push(data);
              break;
            case "ptlLt":
              data.조사항목 = "엽병장";
              data.조사항목코드 = 10000006;
              data.조사항목값 = item[k];
              growthData.push(data);
              break;
            case "crownDmt":
              data.조사항목 = "관부직경";
              data.조사항목코드 = 10000009;
              data.조사항목값 = item[k];
              growthData.push(data);
              break;
            case "planCount":
              data.조사항목 = "개화수";
              data.조사항목코드 = 10000010;
              data.조사항목값 = item[k];
              growthData.push(data);
              break;
            case "fristCount":
              data.조사항목 = "착과수";
              data.조사항목코드 = 10000011;
              data.조사항목값 = item[k];
              growthData.push(data);
              break;
            case "fristFclu":
              data.조사항목 = "화방수";
              data.조사항목코드 = 10000031;
              data.조사항목값 = item[k];
              growthData.push(data);
              break;
          }
        });
      });

      var excelData = XLSX.utils.json_to_sheet(growthData); // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new(); // 새 시트 생성
      XLSX.utils.book_append_sheet(workBook, excelData, "생육데이터 리스트"); // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, "생육데이터.xlsx"); // 엑셀파일 만듬
      this.btnDisabled = false;
    },
  },
};
</script>
